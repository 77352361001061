* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Nunito", sans-serif;
  background-color: #ECECEC;
}

.container-element {
  margin: 0 auto;
  max-width: 90%;
}

.title-landing {
  padding: 50px 0;
  font-size: 24px;
  text-align: center;
}
@media (min-width: 1200px) {
  .title-landing {
    padding: 100px 0;
    font-size: 32px;
  }
}
@media (min-width: 1800px) {
  .title-landing {
    font-size: 48px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.btn-menu {
  width: 40px;
  height: 40px;
  color: #232323;
  font-size: 30px;
  border: none;
  background-color: transparent;
}

.social-icon {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 3px solid #4FD3C4;
  border-radius: 50%;
  font-size: 28px;
  color: #4FD3C4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.social-icon span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.card-stack {
  width: 150px;
  height: 60px;
  background: linear-gradient(85deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}
.card-stack img {
  width: 25px;
  height: auto;
}
.card-stack p {
  color: #F6F6F6;
}
.card-project {
  scroll-snap-align: center;
  min-width: 280px;
  width: 300px;
  height: 350px;
  background-color: #F6F6F6;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  -webkit-box-shadow: 0 5px 10px rgba(35, 35, 35, 0.5);
  box-shadow: 0 5px 10px rgba(35, 35, 35, 0.5);
}
.card-project article {
  width: 100%;
  height: 100%;
}
.card-project article img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.card-project article div {
  height: 100%;
  max-height: 200px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.card-project article div h3 {
  font-size: 18px;
  text-align: start;
  color: #232323;
}
.card-project article div h4 {
  font-size: 16px;
  text-align: start;
  color: #232323;
}
.card-work {
  border-bottom: 1px solid rgba(35, 35, 35, 0.5);
}
@media (min-width: 600px) {
  .card-work {
    border-bottom: none;
  }
}
.card-work > * {
  margin-bottom: 15px;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #F6F6F6;
  -webkit-box-shadow: 0 5px 10px rgba(35, 35, 35, 0.4);
  box-shadow: 0 5px 10px rgba(35, 35, 35, 0.4);
  z-index: 20;
}
.header__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.header__content .menu-active {
  display: block;
  animation: fadeIn 1.5s both;
}
.header__content--logotipo img {
  width: 100%;
  height: auto;
}
.header__content--nav {
  width: 100%;
  display: none;
  position: absolute;
  top: 100px;
  left: 0;
  padding: 20px;
  background-color: #F6F6F6;
  border-top: 1px solid #232323;
  border-bottom: 1px solid #232323;
  animation: fadeOut 2s both;
}
@media (min-width: 900px) {
  .header__content--nav {
    position: initial;
    border: none;
    display: block;
    animation: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }
}
.header__content--nav ul {
  width: 100%;
}
.header__content--nav ul li {
  list-style: none;
  margin-bottom: 15px;
}
.header__content--nav ul li a {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  text-decoration: none;
  color: #232323;
}
.header__content--nav ul li a:hover {
  text-decoration: underline;
  color: #4FD3C4;
}
@media (min-width: 900px) {
  .header__content--nav ul li {
    margin-bottom: 0;
  }
}
@media (min-width: 900px) {
  .header__content--nav ul {
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}
@media (min-width: 1800px) {
  .header__content--nav ul {
    max-width: 60%;
  }
}
@media (min-width: 900px) {
  .header__content .btn-menu {
    display: none;
  }
}

.hero {
  width: 100%;
  height: 75vh;
  background-image: url(./assets/img/hero-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.hero__content {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 599px) {
  .hero__content {
    height: 60%;
  }
}
.hero__content--title {
  margin: 0 auto;
  padding: 20px;
  color: #F6F6F6;
  text-align: center;
}
@media (min-width: 600px) {
  .hero__content--title {
    font-size: 32px;
  }
}
@media (min-width: 1800px) {
  .hero__content--title {
    padding: 50px;
    max-width: 80%;
    font-size: 48px;
  }
}
.hero__content--social {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}
@media (min-width: 900px) {
  .hero__content--social {
    max-width: 70%;
  }
}
@media (min-width: 1800px) {
  .hero__content--social {
    padding: 50px;
    max-width: 50%;
  }
}
.hero__content--social .social-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}
.hero__content--photo {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 100px);
  width: 200px;
}
@media (min-width: 1800px) {
  .hero__content--photo {
    left: calc(50% - 150px);
    bottom: 30px;
    width: 300px;
    height: 300px;
  }
}
.hero__content--photo img {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 5px solid #F6F6F6;
}

.about {
  margin: 50px 0 0;
}
.about__content--description {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  gap: 20px;
  place-content: center;
}
@media (min-width: 900px) {
  .about__content--description {
    grid-template-columns: repeat(auto-fill, calc(50% - 30px));
  }
}
.about__content--description div p {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 20px;
}
.about__content--description div p a {
  color: #232323;
  font-weight: 700;
}
@media (min-width: 900px) {
  .about__content--description div p {
    font-size: 18px;
    line-height: 29px;
  }
}
@media (min-width: 1800px) {
  .about__content--description div p {
    font-size: 20px;
  }
}
.about__content--description figure {
  width: 100%;
}
.about__content--description figure img {
  width: 100%;
}

.portfolio {
  width: 100%;
  min-width: 320px;
}
.portfolio__content {
  width: 100%;
}
.portfolio__content--projects {
  width: 100%;
}
.portfolio__content--projects .project-list {
  padding: 20px;
  width: 100%;
  min-height: 400px;
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
}
@media (min-width: 900px) {
  .portfolio__content--projects .project-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 30px;
    place-content: center;
    place-items: center;
    overflow: hidden;
  }
}

.stack {
  width: 100%;
  padding: 40px 0;
  background: url(./assets/img/tech.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: brightness(60%);
}
.stack-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  gap: 10px;
  place-content: center;
  place-items: center;
}
.stack__content--title {
  margin-bottom: 40px;
  text-align: center;
  font-size: 32px;
  color: #F6F6F6;
}

.experience {
  margin-bottom: 50px;
}
@media (min-width: 900px) {
  .experience {
    margin-bottom: 100px;
  }
}
.experience__content--list .work-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 20px;
  justify-content: center;
  justify-items: center;
}
@media (min-width: 900px) {
  .experience__content--list .work-list {
    grid-template-columns: repeat(auto-fill, calc(50% - 30px));
    gap: 30px;
  }
}

.contact {
  margin-bottom: 50px;
}
@media (min-width: 1200px) {
  .contact {
    margin-bottom: 100px;
  }
}
.contact-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 20px;
  place-content: center;
  place-items: center;
}
.contact-list a {
  width: 200px;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: #232323;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.contact-list a button {
  margin-right: 20px;
}

.footer {
  width: 100%;
  height: 90px;
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.footer__content--text {
  font-size: 18px;
}

