.hero {
    width: 100%;
    height: 75vh;
    background-image: url(./assets/img/hero-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__content {
        height: 80%;
        @include flex(center, center, column);

        @include for-phone-only {
            height: 60%;
        }

        &--title {
            margin: 0 auto;
            padding: 20px;
            color: $light-color;
            text-align: center;

            @include for-tablet-portrait-up {
                font-size: $xx-lg;
            }

            @include for-big-desktop-up {
                padding: 50px;
                max-width: 80%;
                font-size: $xxx-lg;
            }
        }

        &--social {
            width: 90%;
            margin: 0 auto;
            padding: 20px 0;

            @include for-tablet-landscape-up {
                max-width: 70%;
            }
            
            @include for-big-desktop-up {
                padding: 50px;
                max-width: 50%;
            }

            .social-list {
                @include flex(center, space-around);
            }
        }

        &--photo {
            position: absolute;
            bottom: 10px;
            left: calc(50% - 100px);
            width: 200px;

            @include for-big-desktop-up {
                left: calc(50% - 150px);
                bottom: 30px;
                width: 300px;
                height: 300px;
            }
            
            img {
                width: 100%;
                aspect-ratio: 1/1;
                border-radius: 50%;
                object-fit: cover;
                object-position: center;
            
                border: 5px solid $light-color;
            }
        }
    }
}