$primary-color: #4FD3C4;
$dark-color: #232323;
$light-color: #F6F6F6;
$bg-color: #ECECEC;
$gray-color: #C4C4C4;
$principal-font: 'Nunito', sans-serif;
$sm: 14px;
$normal: 16px;
$md: 18px;
$lg: 20px;
$x-lg: 24px;
$xx-lg: 32px;
$xxx-lg: 48px;