.about {
    margin: 50px 0 0;

    &__content {
        &--description {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, calc(300px - 20px));
            gap: 20px;
            place-content: center;

            @include for-tablet-landscape-up {
                grid-template-columns: repeat(auto-fill, calc(50% - 30px));
            }

            div {
                p {
                    font-size: $sm;
                    line-height: calc($lg + 5px);
                    margin-bottom: 20px;

                    a {
                        color: $dark-color;
                        font-weight: 700;
                    }

                    @include for-tablet-landscape-up {
                        font-size: $md;
                        line-height: calc($x-lg + 5px);
                    }

                    @include for-big-desktop-up {
                        font-size: $lg;
                    }
                }
            }

            figure {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
    }
}