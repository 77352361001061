* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
      scroll-behavior: smooth;
  }
  
  body {
      font-family: $principal-font;
      background-color: $bg-color;
  }

  .container-element {
      margin: 0 auto;
      max-width: 90%;
  }