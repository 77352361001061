.portfolio {
    width: 100%;
    min-width: 320px;

    &__content {
        width: 100%;
        
        &--projects {
            width: 100%;

            .project-list {
                padding: 20px;
                width: 100%;
                min-height: 400px;
                display: flex;
                gap: 10px;
                overflow-x: scroll;
                overscroll-behavior-x: contain;
                scroll-snap-type: x proximity;
    
    
                @include for-tablet-landscape-up {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 350px);
                    gap: 30px;
                    place-content: center;
                    place-items: center;
                    overflow: hidden;
                }
            }
        }
    }
}