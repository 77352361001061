.footer {
    width: 100%;
    height: 90px;
    background-color: $light-color;
    @include flex(center, center);

    &__content {
        &--text {
            font-size: $md;
        }
    }
}