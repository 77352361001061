.social-icon {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 3px solid $primary-color;
    border-radius: 50%;
    font-size: 28px;
    color: $primary-color;
    cursor: pointer;
    @include flex(center, center);

    span {
        @include flex(center, center);
    }
}