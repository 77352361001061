.card {
    &-stack{
        width: 150px;
        height: 60px;
        background: linear-gradient(85deg, rgba(white, 0)0%, rgba(white, 0.2)100%);
        backdrop-filter: blur(5px);
        border-radius: 10px;
        border: 1px solid rgba(white, 0.1);
        @include flex(center, space-around);

        img {
            width: 25px;
            height: auto;
        }

        p {
            color: $light-color;
        }
    }

    &-project {
        scroll-snap-align: center;
        min-width: 280px;
        width: 300px;
        height: 350px;
        background-color: $light-color;
        cursor: pointer;
        border-radius: 5px;
        border: none;
        @include shadow(0, 5px, 10px, rgba($dark-color, 0.5));

        article {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
                object-position: center;
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
            }

            div {
                height: 100%;
                max-height: 200px;
                padding: 20px;
                @include flex(flex-start, space-between, column);

                h3 {
                    font-size: $md;
                    text-align: start;
                    color: $dark-color;
                }

                h4 {
                    font-size: $normal;
                    text-align: start;
                    color: $dark-color;
                }
            }
        }
    }

    &-work {
        border-bottom: 1px solid rgba($dark-color, 0.5);

        @include for-tablet-portrait-up {
            border-bottom: none;
        }

        & > * {
            margin-bottom: 15px;
        }
    }
}