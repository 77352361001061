.title-landing {
    padding: 50px 0;
    font-size: $x-lg;
    text-align: center;

    @include for-desktop-up {
        padding: 100px 0;
        font-size: $xx-lg;
    }

    @include for-big-desktop-up {
        font-size: $xxx-lg;
    }
}